import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  let baseURL = base || "/api";

  const instance = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
    },
  });

  // 요청 인터셉터를 사용하여 GET, POST일 때만 Content-Type을 multipart/form-data로 설정
  instance.interceptors.request.use(
    (config) => {
      if (["get", "post"].includes(config.method)) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else if (["delete", "put"].includes(config.method)) {
        // GET, POST가 아닐 때는 Content-Type을 삭제
        delete config.headers["Content-Type"];
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

/**
 * [049-01] 포스팅 상세 / 임시저장 포스팅 조회 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드
 * @returns {Promise} result
 */
export const getPostingDetailAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting-view.do", { params: { ...payload } });
};

/**
 * [049-02] 포스팅 (임시)저장 API
 * @param {string} grpCd : 교회/단체 코드(필수)
 * @param {string} postingCnts : 포스팅 내용
 * @param {string} postingCntsCut : 포스팅 내용 요약
 * @param {string} regLDate : 로컬작성일시(필수)
 * @desc postingCd 발급
 */
export const savePostingAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting.do", objToFormData(payload));
};

/**
 * [049-03] 포스팅 완료 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드(필수)
 * @param {string} postingCnts : 포스팅 내용
 * @param {string} postingCntsCut : 포스팅 내용 요약
 * @param {string} regLDate : 로컬작성일시(필수)
 * @param {string} tempSaveYn : 임시저장여부
 * @param {string} uploadFileCd : 업로드한 이미지 코드(콤마로 구분, 최대20개)
 */
export const updatePostingAPI = async (payload) => {
  const encodedPayload = {
    ...payload,
    postingCnts: encodeURIComponent(payload.postingCnts),
    postingCntsCut: encodeURIComponent(payload.postingCntsCut),
  };
  //return await axiosInstance("/ajax").put("/grp/posting/comment.do", { payload: null }, { params: { ...payload } });
  return await axiosInstance("/ajax").post("/grp/posting.do", objToFormData(payload));
};

/**
 * [049-04] 포스팅 삭제 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드
 */
export const deletePostingAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting-delete.do", objToFormData(payload));
  //return await axiosInstance("/ajax").delete("/grp/posting.do", { params: { ...payload } });
};

/**
 * [049-05] 포스팅 댓글 목록 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드
 * @returns {Promise} result : object
 */
export const getPostingCmtListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting/comment.do", { params: { ...payload } });
};

/**
 * [049-06] 포스팅 댓글 저장 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} comment : 댓글 내용
 * @param {string} regLDate : 로컬작성일시
 * @param {string} grpCd : 교회/단체 코드
 * @returns {Promise} result : 1
 */
export const savePostingCmtAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting/comment.do", objToFormData(payload));
};

/**
 * [049-07] 포스팅 댓글 수정 API
 * @param {string} commentCd : 댓글 코드
 * @param {string} comment : 댓글 내용
 * @param {string} regLDate : 로컬작성일시
 * @param {string} grpCd : 교회/단체 코드
 */
export const updatePostingCmtAPI = async (payload) => {
  return await axiosInstance("/ajax").put("/grp/posting/comment.do", { payload: null }, { params: { ...payload } });
  //return await axiosInstance("/ajax").put("/grp/posting/comment.do", payload);
  //return await axiosInstance("/ajax").put("/grp/posting/comment.do", objToFormData(payload));
};

/**
 * [049-08] 포스팅 댓글 삭제 API
 * @param {string} commentCd : 댓글 코드
 * @param {string} grpCd : 교회/단체 코드
 */
export const delPostingCmtAPI = async (payload) => {
  //return await axiosInstance("/ajax").delete("/grp/posting/comment.do", { params: { ...payload } });
  return await axiosInstance("/ajax").post("/grp/posting/comment-delete.do", objToFormData(payload));
  //return await axiosInstance("/ajax").delete("/grp/posting/comment.do", { params: { ...payload }, data: objToFormData({}) });
};

/**
 * [049-09] 포스팅 아멘 목록 API
 * @param {string} postingCd : 포스팅 코드
 * @method GET
 */
export const getPostingAmenListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting/amen.do", { params: { ...payload } });
};

/**
 * [049-10] 포스팅 아멘 저장 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드
 * @returns {Promise} result : Y/N
 */
export const setPostingAmenAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting/amen.do", objToFormData(payload));
};

/**
 * [049-11] 포스팅 아멘 삭제 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드
 * @returns {Promise} result : Y/N
 */
export const delPostingAmenAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting/amen-delete.do", objToFormData(payload));
  //return await axiosInstance("/ajax").delete("/grp/posting/amen.do", { params: { ...payload } });
};

/**
 * [049-12] 포스팅(교회/단체) 권한 조회 API
 * @param {string} grpCd : 그룹 코드
 * @returns {Promise} result : {grpCd : 교회/단체 코드, grpName :  교회/단체명 , grpMngrYn:관리자여부, bldrYn:개설자여부, grpMngrRole:그룹관리자등급(0:일반,1:대표)}
 */
export const getPostingAuthAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting/auth.do", { params: { ...payload } });
};
