import {
  getPostingDetailAPI,
  savePostingAPI,
  getPostingAmenListAPI,
  getPostingCmtListAPI,
  setPostingAmenAPI,
  delPostingAmenAPI,
  savePostingCmtAPI,
  delPostingCmtAPI,
  updatePostingCmtAPI,
  deletePostingAPI,
  getPostingAuthAPI,
  updatePostingAPI,
} from "./api/posting";

export const usePostingStore = defineStore("posting", () => {
  const state = reactive({
    detail: {},
    postingCd: "",
    auth: {},
    submitBtn: false,
  });

  const modalState = reactive({
    open: false,
    list: [],
    groupedCmt: [],
    cmtList: [],
    page: 0,
  });

  const amenState = reactive({
    open: false,
    list: [],
  });

  /* async function getPostingDetailActor(payload) {
    return await getPostingDetailAPI(payload && payload);
  } */
  async function getPostingDetailActor(payload) {
    try {
      const { data: res = {} } = await getPostingDetailAPI(payload && payload);
      //if (!res?.result) throw new Error("포스팅 조회에 실패했습니다.");
      state.detail = res.result;
      state.postingCd = res.result.postingCd;
      console.log("getPostingDetailActor ::: ", state.detail);
      return state.detail;
    } catch (err) {
      console.error(`[ERROR][posting.getPostingDetailActor] ${err}`);
    }
  }

  async function savePostingActor(payload) {
    try {
      const { data: res = {} } = await savePostingAPI(payload);
      console.log("savePostingActor ::: ", res);
      //if (!res?.result) throw new Error("포스팅 저장에 실패했습니다.");
      return res;
    } catch (err) {
      console.error(`[ERROR][posting.savePostingActor] ${err}`);
    }
  }

  async function getPostingAmenListActor(payload) {
    try {
      const { data: res = {} } = await getPostingAmenListAPI(payload);
      if (!res?.result) throw new Error("포스팅 아멘 목록 조회에 실패했습니다.");

      if (res.result.length == 0) {
        amenState.list = [];
        state.detail.amenCount = 0;
        return amenState.list;
      }

      amenState.list = res.result.map((itm) => ({
        ...itm,
        memName: itm.MemName,
        memCd: itm.MemCd,
        imgSrc: itm.profilePhoto ? `https://jwj.kr/upload/member/${itm.profilePhoto}` : "",
      }));

      state.detail.amenCount = amenState.list.length;

      console.log(`getPostingAmenListActor  :::`, amenState.list);

      return amenState.list;
    } catch (err) {
      console.error(`getPostingAmenListActor ${err}`);
    }
  }

  async function getPostingCmtListActor({ postingCd, refresh = false }) {
    try {
      if (refresh) {
        modalState.groupedCmt = [];
        modalState.cmtList = [];
        modalState.list = [];
      }

      const { data: res = {} } = await getPostingCmtListAPI({ postingCd });
      if (!res?.result) throw new Error("포스팅 댓글 목록 조회에 실패했습니다.");
      modalState.cmtList = [...res.result.map((itm) => ({ ...itm, mode: "read", postCnts: itm.comment, postCd: postingCd }))];
      modalState.groupedCmt = getCmtPaging(modalState.cmtList, 10);
      state.detail.commentCount = modalState.cmtList.length;
      return true;
    } catch (err) {
      console.error(`getPostingCmtListActor ${err}`);
    }
  }

  function getCmtPaging(array, perGroup) {
    const result = {};
    for (let i = 0, max = array.length; i < max; i++) {
      const groupIndex = Math.floor(i / perGroup);
      if (!result[groupIndex]) {
        result[groupIndex] = [];
      }
      result[groupIndex].push(array[i]);
    }
    return result;
  }

  /**
   * @param {string} postingCd : 포스팅 코드
   */
  async function setPostingAmenActor(payload) {
    try {
      const { data: res = {} } = await setPostingAmenAPI(payload);
      if (res?.result != 1) throw new Error("포스팅 아멘 등록에 실패했습니다.");
      await getPostingAmenListActor({ postingCd: payload.postingCd });
      console.log("setPostingAmenActor ::: ", res);
      return res;
    } catch (err) {
      console.error(`setPostingAmenActor ${err}`);
      alert(err);
    }
  }

  async function delPostingAmenActor(payload) {
    try {
      console.log("delPostingAmenActor ::: ", payload);
      const { data: res = {} } = await delPostingAmenAPI(payload);
      if (res?.result != 1) throw new Error("포스팅 아멘 삭제에 실패했습니다.");
      await getPostingAmenListActor({ postingCd: payload.postingCd });
      return res;
    } catch (err) {
      console.error(`delPostingAmenActor ${err}`);
    }
  }

  async function savePostingCmtActor(payload) {
    const { data: res = {} } = await savePostingCmtAPI(payload);
    return res.result;
  }

  async function delPostingCmtActor(payload) {
    const { data: res = {} } = await delPostingCmtAPI(payload);
    return res;
  }
  async function updatePostingCmtActor(payload) {
    const { data: res = {} } = await updatePostingCmtAPI(payload);
    return res;
  }

  async function deletePostingActor(payload) {
    const { data: res = {} } = await deletePostingAPI(payload);
    return res;
  }

  async function getPostingAuthActor(payload) {
    const { data: res = {} } = await getPostingAuthAPI(payload);
    state.auth = res.result;
    return state.auth;
  }

  /**
   * 임시저장, 완료를 한다. 차이는 tempSaveYn
   */
  async function updatePostingActor(payload) {
    try {
      const { data: res = {} } = await updatePostingAPI(payload);
      if (!res?.result) throw new Error("포스팅 저장에 실패했습니다.");
      console.log("updatePostingActor ::: ", res);
      return res;
    } catch (err) {
      console.error(`updatePostingActor ${err}`);
    }
  }
  return {
    state,
    modalState,
    amenState,
    getPostingDetailActor,
    getPostingAmenListActor,
    getPostingCmtListActor,
    setPostingAmenActor,
    delPostingAmenActor,
    savePostingCmtActor,
    delPostingCmtActor,
    updatePostingCmtActor,
    savePostingActor,
    deletePostingActor,
    getPostingAuthActor,
    updatePostingActor,
  };
});
